import React from "react"

/* Import Global Providers */
import WhitneyHtmlProvider from "~providers/whitneyHtml"
import LoadingProvider from "~providers/loading"
import AudioContextProvider from "~providers/audioContext"
import AudioSamplesProvider from "~providers/audioSamples"
import PlayingProvider from "~providers/playing"
import ThreeProvider from "~providers/three"
import TimeProvider from "~providers/time"

/* Import Global Component(s) */
import Whitney from "~components/whitney/whitney"

/* Import Local Style(s) */
import * as styles from "./layout.module.scss"

const Layout = ({ children, pageContext }) => {
  return (
    <WhitneyHtmlProvider>
      <AudioContextProvider>
        <LoadingProvider>
          <AudioSamplesProvider>
            <PlayingProvider>
              <ThreeProvider>
                <TimeProvider>
                  <main className={`main ${styles.main}`}>{children}</main>
                  <Whitney />
                </TimeProvider>
              </ThreeProvider>
            </PlayingProvider>
          </AudioSamplesProvider>
        </LoadingProvider>
      </AudioContextProvider>
    </WhitneyHtmlProvider>
  )
}

export default Layout
