import React, { useState } from "react"

/* Import Global Context(s) */
import ReactAudioContext from "~context/audioContext"

const isBrowser = typeof window !== "undefined"

const AudioContextProvider = ({ children }) => {
  // window build error here
  const [audioContext] = useState(isBrowser ? new AudioContext() : null)

  return (
    <ReactAudioContext.Provider value={{ audioContext }}>
      {children}
    </ReactAudioContext.Provider>
  )
}

export default AudioContextProvider
