/* Import Global Assets */
import droneVideoSrc from "~assets/video/drone-compressed.mp4"

const html = `<div class="home"><div class="content">
<script type="text/javascript">
if (localStorage.getItem('dark') === 'true') document.body.classList.add('dark');
</script>


<header class="header">
<a href="#main" class="skip-to-content body-medium-bold">Skip to main content</a>

<div class="navigations">

<div class="w-wrapper content-wrapper">
<div class="w active" style="opacity: 1;">
<a href="https://whitney.org/" class="w__link" tabindex="-1" aria-label="Whitney Museum of American Art">
  <img class="w__text" alt="" src="/whitney/whitney_text-cd9af0bc8d5e34a937e6139361c0b10177579d33736b00f.png" style="left: 22.2485px; right: auto; width: 632.119px; height: 109.839px; display: block;">
</a>

<svg class="w__svg" viewBox="0 0 100 100" preserveAspectRatio="none" style="height: 280px;">
  <polyline points="0,0 0,100 67.29780673934079,0 67.29780673934079,78.57142857142857 100,0" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></polyline>
</svg>
</div>
</div>

<div class="navigations__spacer"></div>
<div class="mini-header-wrapper">
<div class="content-wrapper">
<aside class="mini-header">
  <div class="mini-header__left">
    <a class="mini-header__title" href="https://whitney.org/" style="opacity: 0;">Whitney Museum of American Art</a>
  </div>
  <div id="kw-swap-00"></div>
  <div class="mini-header__right">
    <div class="nav-actions">
        <div class="nav-actions__hours body-medium">
          <a href="https://whitney.org/visit#hours">Open today: 10:30 am–6 pm</a>
        </div>

        <a class="nav-actions__link" onclick="if (useAnalytics) dataLayer.push({'event': 'gaEvent', 'category': 'Buttons', 'action': 'click', 'label': 'Ticketing (Header)' });" href="https://whitney.org/ticketing-services">Buy Tickets</a>

<a class="nav-actions__link" onclick="if (useAnalytics) dataLayer.push({'event': 'gaEvent', 'category': 'Buttons', 'action': 'click', 'label': 'Membership (Header)' });" href="https://whitney.org/membership-services">Become a Member</a>

    </div>

    <button class="mini-header__menu" type="button" aria-label="Scroll to menu" onclick="if (useAnalytics) dataLayer.push({'event': 'gaEvent', 'category': 'Buttons', 'action': 'click', 'label': 'Menu (Header)' });">
      Menu
    </button>

    <button class="mini-header__menu-small" type="button" aria-label="Menu" aria-haspopup="true" onclick="if (useAnalytics) dataLayer.push({'event': 'gaEvent', 'category': 'Buttons', 'action': 'click', 'label': 'Menu (Header)' });">
      Menu
    </button>
  </div>
  <img src="/KW.png" style="width: 100%; display: block; position: absolute; z-index: 1; max-width: 25vw; min-width: 200px; right: 16px;
  top: 4rem; ">
</aside>
</div>
</div>

<div class="primary-nav nav content-wrapper primary-nav--inactive">
<nav class="primary-nav__list-wrapper" aria-label="Primary navigation">
<ul class="primary-nav__list p-0">

    <li class="primary-nav__item">
      <button aria-haspopup="true" class="primary-nav__link primary-nav__link--toggle" data-toggle="visit">
        <span class="nav__text">Visit</span>
      </button>

        <div class="secondary-nav" aria-hidden="false" aria-label="Secondary navigation" data-toggle="visit">
          <ul class="secondary-nav__list">

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/visit">Plan your visit</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/ticketing-services">Buy tickets</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/support/membership">Membership</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/visit/dining">Dining</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/visit/access">Accessibility</a>
              </li>
          </ul>
        </div>
    </li>

    <li class="primary-nav__item">
      <button aria-haspopup="true" class="primary-nav__link primary-nav__link--toggle" data-toggle="exhibitions &amp; events">
          <span class="nav__text-small">Exhibitions</span>
        <span class="nav__text">Exhibitions &amp; Events</span>
      </button>

        <div class="secondary-nav" aria-hidden="false" aria-label="Secondary navigation" data-toggle="exhibitions &amp; events">
          <ul class="secondary-nav__list">

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/exhibitions">Exhibitions</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/events">Events</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/exhibitions/film">Film</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/exhibitions/performance">Performance</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/exhibitions/the-biennial">The Biennial</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/exhibitions/past">Past exhibitions</a>
              </li>
          </ul>
        </div>
    </li>

    <li class="primary-nav__item">
      <button aria-haspopup="true" class="primary-nav__link primary-nav__link--toggle" data-toggle="art &amp; artists">
          <span class="nav__text-medium">Art</span>
        <span class="nav__text">Art &amp; Artists</span>
      </button>

        <div class="secondary-nav" aria-hidden="false" aria-label="Secondary navigation" data-toggle="art &amp; artists">
          <ul class="secondary-nav__list">

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/collection/works">Collection</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/artists">Artists</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/artport">artport</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/audio">Audio</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/media">Videos</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/essays">Essays</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/conservation">Conservation</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/research">Research</a>
              </li>
          </ul>
        </div>
    </li>

    <li class="primary-nav__item">
      <button aria-haspopup="true" class="primary-nav__link primary-nav__link--toggle" data-toggle="learn">
        <span class="nav__text">Learn</span>
      </button>

        <div class="secondary-nav" aria-hidden="false" aria-label="Secondary navigation" data-toggle="learn">
          <ul class="secondary-nav__list">

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education">Education</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education/access">Access Programs</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education/community">Community</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education/courses">Courses</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education/families">Families</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/visit/groups">Groups</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education/k-12">K–12</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education/public-programs">Public Programs</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/education/teens">Teens</a>
              </li>
          </ul>
        </div>
    </li>

    <li class="primary-nav__item">
      <button aria-haspopup="true" class="primary-nav__link primary-nav__link--toggle" data-toggle="shop">
        <span class="nav__text">Shop</span>
      </button>

        <div class="secondary-nav" aria-hidden="false" aria-label="Secondary navigation" data-toggle="shop">
          <ul class="secondary-nav__list">

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://shop.whitney.org/">Shop online</a>
              </li>

              <li class="secondary-nav__item">
                <a class="secondary-nav__link" href="https://whitney.org/membership-services-reopening">Gift membership</a>
              </li>
          </ul>
        </div>
    </li>

  <li class="primary-nav__item primary-nav__item--search">
    <button aria-haspopup="true" class="primary-nav__link primary-nav__link--toggle primary-nav__link--search" data-toggle="search">
      Search
    </button>

    <div class="secondary-nav secondary-nav--search" aria-hidden="false" aria-label="Secondary navigation" data-toggle="search">
      <form class="nav-search" role="search">
        <input type="search" placeholder="Search whitney.org" class="nav-search__input input input--large">
        <button type="submit" class="search-submit btn btn--large" aria-label="Submit search">Go</button>
      </form>
    </div>
  </li>
</ul>
</nav>
</div>

<div class="primary-nav-small nav primary-nav-small--inactive" role="dialog">
<div class="primary-nav-small-header">
<a href="https://whitney.org/" class="primary-nav-small-header__title body-medium-bold">Whitney Museum of American Art</a>

<button class="primary-nav-small-header__close" type="button" aria-label="Close menu">Close</button>
</div>

<div class="content-wrapper">
<nav class="primary-nav-small__list-wrapper">
  <ul class="primary-nav-small__list m-0 p-0">

      <li class="primary-nav-small__item">
        <button class="primary-nav-small__link primary-nav-small__link--toggle" aria-expanded="false">
          Visit
        </button>

        <div class="secondary-nav-small" aria-hidden="false" aria-label="Secondary navigation">
          <ul class="secondary-nav-small__list p-0">

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/visit">Plan your visit</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/ticketing-services">Buy tickets</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/support/membership">Membership</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/visit/dining">Dining</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/visit/access">Accessibility</a>
              </li>
          </ul>
        </div>
      </li>

      <li class="primary-nav-small__item">
        <button class="primary-nav-small__link primary-nav-small__link--toggle" aria-expanded="false">
          Exhibitions &amp; Events
        </button>

        <div class="secondary-nav-small" aria-hidden="false" aria-label="Secondary navigation">
          <ul class="secondary-nav-small__list p-0">

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/exhibitions">Exhibitions</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/events">Events</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/exhibitions/film">Film</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/exhibitions/performance">Performance</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/exhibitions/the-biennial">The Biennial</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/exhibitions/past">Past exhibitions</a>
              </li>
          </ul>
        </div>
      </li>

      <li class="primary-nav-small__item">
        <button class="primary-nav-small__link primary-nav-small__link--toggle" aria-expanded="false">
          Art &amp; Artists
        </button>

        <div class="secondary-nav-small" aria-hidden="false" aria-label="Secondary navigation">
          <ul class="secondary-nav-small__list p-0">

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/collection/works">Collection</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/artists">Artists</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/artport">artport</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/audio">Audio</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/media">Videos</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/essays">Essays</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/conservation">Conservation</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/research">Research</a>
              </li>
          </ul>
        </div>
      </li>

      <li class="primary-nav-small__item">
        <button class="primary-nav-small__link primary-nav-small__link--toggle" aria-expanded="false">
          Learn
        </button>

        <div class="secondary-nav-small" aria-hidden="false" aria-label="Secondary navigation">
          <ul class="secondary-nav-small__list p-0">

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education">Education</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education/access">Access Programs</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education/community">Community</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education/courses">Courses</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education/families">Families</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/visit/groups">Groups</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education/k-12">K–12</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education/public-programs">Public Programs</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/education/teens">Teens</a>
              </li>
          </ul>
        </div>
      </li>

      <li class="primary-nav-small__item">
        <button class="primary-nav-small__link primary-nav-small__link--toggle" aria-expanded="false">
          Shop
        </button>

        <div class="secondary-nav-small" aria-hidden="false" aria-label="Secondary navigation">
          <ul class="secondary-nav-small__list p-0">

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://shop.whitney.org/">Shop online</a>
              </li>

              <li class="secondary-nav-small__item">
                <a class="secondary-nav-small__link" href="https://whitney.org/membership-services-reopening">Gift membership</a>
              </li>
          </ul>
        </div>
      </li>

    <li class="primary-nav-small__item">
      <button aria-haspopup="true" class="primary-nav-small__link primary-nav-small__link--search">
        Search
      </button>

      <form class="nav-small-search" role="search">
        <input type="search" placeholder="Search whitney.org" class="nav-small-search__input input input--large">
        <button type="submit" class="search-submit btn btn--large" aria-label="Submit search">Go</button>
      </form>
    </li>
  </ul>
</nav>

  <a href="https://whitney.org/visit#hours" class="primary-nav-small__hours">Open today: 10:30 am–6 pm</a>
</div>

<div class="nav-actions nav-actions--small content-wrapper">
  <a class="nav-actions__link" onclick="if (useAnalytics) dataLayer.push({'event': 'gaEvent', 'category': 'Buttons', 'action': 'click', 'label': 'Ticketing (Header)' });" href="https://whitney.org/ticketing-services">Buy Tickets</a>

<a class="nav-actions__link" onclick="if (useAnalytics) dataLayer.push({'event': 'gaEvent', 'category': 'Buttons', 'action': 'click', 'label': 'Membership (Header)' });" href="https://whitney.org/membership-services">Become a Member</a>

</div>
</div>


</div>
</header>





<main id="main" class="whitney-main content-wrapper main--page main--small">
    


<div class="grid">

    <div class="region col col--12">
      <div class="component media-component">
<a href="https://whitney.org/visit">
      <div class="video-wrapper" data-initialized="true">
      <video data-label="Winter 2022 Refreshed Experiential Homepage Video" class="video video-native " preload="auto" crossorigin="anonymous" loop="" autoplay="autoplay" playsinline="" muted="muted"><source src="${droneVideoSrc}" type="video/mp4"></video>
      <button class="video__play playing" aria-label="Pause"></button>
    </div>


</a></div>

      <div class="component text-component">
<h2 class="large">Welcome to the Whitney<strong>&nbsp;</strong></h2><p class="large">Experience the defining museum of American art in the heart of New York City. Mark your calendars for <a href="https://whitney.org/exhibitions/edward-hopper-new-york"><em>Edward Hopper’s New York</em></a>, opening October 19.</p><p class="large">Members
may use their membership cards for admission any time. All other 
visitors are encouraged to book timed tickets in advance for best 
availability. Learn more about our <a href="https://whitney.org/visit/policies">safety guidelines</a> and <a href="https://whitney.org/ticketing-services">plan your trip early</a>.</p><p><a class="btn" href="https://whitney.org/ticketing-services">Book Tickets</a> <a class="btn" href="https://whitney.org/membership-info">Members</a> <a class="btn" href="https://whitney.org/visit">What to Expect</a></p>
</div>

      <hr class="horizontal-rule-component component">

      <div class="component exhibition-archive-component">
<section class="exhibitions">
<section class="rail rail--large">
    <a class="rail__title" href="https://whitney.org/exhibitions">
      <h2 class="m-0">Exhibitions</h2>
</a>
    <a class="rail__view-all arrow-link" href="https://whitney.org/exhibitions">View all</a>



  <div class="exhibitions-rail-list">
      <div class="exhibitions-rail-list-item">
        <a href="https://whitney.org/exhibitions/dawn-of-a-new-age">
            <img class="image" sizes="(min-width: 800px) 50vw, 100vw" alt="" draggable="auto" srcset="/whitney/small_Bloch_Mountain_1916_cc.jpg 512w, /whitney/medium_Bloch_Mountain_1916_cc.jpg 1024w, /whitney/large_Bloch_Mountain_1916_cc.jpg 2048w" src="/whitney/large_Bloch_Mountain_1916_cc.jpg">
          <h3 class="exhibitions-rail-list-item__title m-0">
            At the Dawn of a New Age: Early Twentieth-Century American Modernism
          </h3>

          <h4 class="m-0 gray">
            May 7, 2022–Jan 2023
          </h4>
</a>          </div>
      <div class="exhibitions-rail-list-item">
        <a href="https://whitney.org/exhibitions/collection-1900-to-1965">
            <img class="image" sizes="(min-width: 800px) 50vw, 100vw" alt="" draggable="auto" srcset="/whitney/small_2018-141_Lewis.jpg 512w, /whitney/medium_2018-141_Lewis.jpg 1024w, /whitney/large_2018-141_Lewis.jpg 2048w" src="/whitney/large_2018-141_Lewis.jpg">


          <h3 class="exhibitions-rail-list-item__title m-0">
            The Whitney’s Collection: Selections from 1900 to 1965
          </h3>

          <h4 class="m-0 gray">
            Jun 28, 2019–
          </h4>
</a>          </div>
      <div class="exhibitions-rail-list-item">
        <a href="https://whitney.org/exhibitions/martine-gutierrez">
            <img class="image" sizes="(min-width: 800px) 50vw, 100vw" alt="" draggable="auto" srcset="/whitney/small_Supremacy_smaller.jpg 512w, /whitney/medium_Supremacy_smaller.jpg 1024w, /whitney/large_Supremacy_smaller.jpg 2048w" src="/whitney/large_Supremacy_smaller.jpg">


          <h3 class="exhibitions-rail-list-item__title m-0">
            Martine Gutierrez:<br>Supremacy
          </h3>

          <h4 class="m-0 gray">
            Sept 2022–Mar 2023
          </h4>
</a>          </div>
      <div class="exhibitions-rail-list-item">
        <a href="https://whitney.org/exhibitions/edward-hopper-new-york">
            <img class="image" sizes="(min-width: 800px) 50vw, 100vw" alt="" draggable="auto" srcset="/whitney/small_70_1098_crop.jpg 512w, /whitney/medium_70_1098_crop.jpg 1024w, /whitney/large_70_1098_crop.jpg 2048w" src="/whitney/large_70_1098_crop.jpg">


          <h3 class="exhibitions-rail-list-item__title m-0">
            Edward Hopper’s New York
          </h3>

          <h4 class="m-0 gray">
            Oct 19, 2022–Mar 5, 2023
          </h4>
</a>          </div>
  </div>
</section>
</section>
</div>

</div>

  

    <div class="region col col--12">
      <hr class="horizontal-rule-component component">

      <div class="component text-component">
<h2 class="large">We stand with Black communities.</h2><p class="large">A <a href="https://whitney.org/we-stand-with-black-communities">message</a> from our director.</p>
</div>

      <hr class="horizontal-rule-component component">

        <div class="component series-component">
    <div class="rail">
  <a class="rail__title" href="https://whitney.org/collection">
    <h2 class="m-0">Dive Into Our Collection</h2>
</a>
  <a class="rail__view-all arrow-link" href="https://whitney.org/collection">View all</a>


<ul class="rail-list">
    <li class="rail-item">
        <a href="https://whitney.org/collection/works/38986">
              <img class="image artwork" sizes="(min-width: 800px) 17vw, 50vw" src="/whitney/2011_65_cropped.jpeg">


          <p class="rail-item__text">
            <strong>Edward Hopper</strong><br>
            <em>Study for Nighthawks</em>
            <br>1941 or 1942
          </p>
</a>        </li>
    <li class="rail-item">
        <a href="https://whitney.org/collection/works/635">
              <img class="image artwork" sizes="(min-width: 800px) 17vw, 50vw" src="/whitney/31_172_cropped.jpeg">


          <p class="rail-item__text">
            <strong>Charles Demuth</strong><br>
            <em>My Egypt</em>
            <br>1927
              <br><span class="gray">On view, Floor 7</span>
          </p>
</a>        </li>
    <li class="rail-item">
        <a href="https://whitney.org/collection/works/61717">
              <img class="image artwork" sizes="(min-width: 800px) 17vw, 50vw" src="/whitney/T_2019_400_1.jpeg">


          <p class="rail-item__text">
            <strong>Alan Michelson</strong><br>
            <em>Wolf Nation</em>
            <br>2018
          </p>
</a>        </li>
    <li class="rail-item">
        <a href="https://whitney.org/collection/works/59967">
              <img class="image artwork" sizes="(min-width: 800px) 17vw, 50vw" src="/whitney/2018_141_cropped.jpeg">


          <p class="rail-item__text">
            <strong>Norman Lewis</strong><br>
            <em>American Totem</em>
            <br>1960
              <br><span class="gray">On view, Floor 7</span>
          </p>
</a>        </li>
    <li class="rail-item">
        <a href="https://whitney.org/collection/works/55877">
              <img class="image artwork" sizes="(min-width: 800px) 17vw, 50vw" src="/whitney/2018_94_cropped.jpeg">


          <p class="rail-item__text">
            <strong>Deana Lawson</strong><br>
            <em>Signs</em>
            <br>2016
          </p>
</a>        </li>
    <li class="rail-item">
        <a href="https://whitney.org/collection/works/38559">
              <img class="image artwork" sizes="(min-width: 800px) 17vw, 50vw" src="/whitney/2010_221_cropped.jpeg">


          <p class="rail-item__text">
            <strong>Cindy Sherman</strong><br>
            <em>Untitled Film Still #23</em>
            <br>1978
          </p>
</a>        </li>
</ul>
</div>


</div>

      <hr class="horizontal-rule-component component">

</div>

  

    <div class="region col col--4">
      <div class="component media-component">
<a href="https://whitney.org/whitney-from-home">
    <img class="image" sizes="100vw" alt="" draggable="auto" srcset="/whitney/small_wfh__1_.jpg 512w, /whitney/medium_wfh__1_.jpg 1024w, /whitney/large_wfh__1_.jpg 2048w" src="/whitney/large_wfh__1_.jpg">

</a></div>

      <div class="component text-component">
<h2><a href="https://whitney.org/whitney-from-home">Whitney from Home</a></h2><p>Engage with American art of the twentieth century to today through our rich array of <a href="https://whitney.org/whitney-from-home">online resources</a>.</p>
</div>

</div>


    <div class="region col col--4">
      <div class="component media-component">
<a href="https://whitney.org/podcast">
    <img class="image" sizes="100vw" alt="" draggable="auto" srcset="/whitney/small_2021_05_25_Day_s_End_2407-crop.jpg 512w, /whitney/medium_2021_05_25_Day_s_End_2407-crop.jpg 1024w, /whitney/large_2021_05_25_Day_s_End_2407-crop.jpg 2048w" src="/whitney/large_2021_05_25_Day_s_End_2407-crop.jpg">

</a></div>

      <div class="component text-component">
<h2><a href="https://whitney.org/podcast">Podcast</a></h2><p>Listen to <a href="https://whitney.org/podcast"><em>Artists Among Us</em></a>,
our newest mode of storytelling by which we consider the complexities 
and contradictions that have culminated in the United States we 
experience today.</p>
</div>

</div>


    <div class="region col col--4">
      <div class="component media-component">
<a href="https://whitney.org/artport">
    <img class="image" sizes="100vw" alt="" draggable="auto" src="/whitney/Screen_Shot_2022-01-09_at_5.54.17_PM.png">

</a></div>

      <div class="component text-component">
<h2><a href="https://whitney.org/artport">artport</a></h2><p>Check out art that's created specifically for the web on <a href="https://whitney.org/artport">artport</a>—the Whitney's gallery space for Internet and new-media art.</p>
</div>

</div>

</div>



</main>  </div>

<footer class="footer" role="contentinfo">
<div class="content-wrapper">
<hr class="footer__hr">

<div class="footer__primary">
  <div class="footer-connect">
    <p class="footer-connect__title">Get more art in your inbox</p>

    <div class="footer-newsletter">
      <!-- Begin MailChimp Signup Form -->
<div id="mc_embed_signup" class="mailchimp">
<form action="//whitney.us13.list-manage.com/subscribe/post?u=387f59a72ae7b64ccae37d5c9&amp;id=2d1244a32b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" rel="noopener" novalidate="novalidate">
<div id="mc_embed_signup_scroll">
  <div class="mc-field-group mailchimp__fields">
    <input type="email" name="EMAIL" autocomplete="email" class="mailchimp__input input input--large body-medium required email" id="mce-EMAIL" placeholder="Email address" aria-label="Email" required="" aria-required="true">
    <input type="submit" value="Sign up" name="subscribe" id="mc-embedded-subscribe" class="btn btn--large mailchimp__btn">
  </div>

  <div class="mc-field-group input-group" style="display:none;">
    <strong>I am interested in: </strong>
    <ul style="margin-bottom: 15px;">
      <li><input type="checkbox" value="1" name="group[8529][1]" id="mce-group[8529]-8529-0" checked="checked">
        <label for="mce-group[8529]-8529-0">General Whitney News (Exhibition and Program Highlights)</label></li>
      <li><input type="checkbox" value="2" name="group[8529][2]" id="mce-group[8529]-8529-1">
        <label for="mce-group[8529]-8529-1">Performances</label></li>
      <li><input type="checkbox" value="4" name="group[8529][4]" id="mce-group[8529]-8529-2">
        <label for="mce-group[8529]-8529-2">Lectures, Talks, Courses</label></li>
      <li><input type="checkbox" value="8" name="group[8529][8]" id="mce-group[8529]-8529-3">
        <label for="mce-group[8529]-8529-3">Family Programs</label></li>
      <li><input type="checkbox" value="16" name="group[8529][16]" id="mce-group[8529]-8529-4">
        <label for="mce-group[8529]-8529-4">School and Educator Programs</label></li>
      <li><input type="checkbox" value="32" name="group[8529][32]" id="mce-group[8529]-8529-5">
        <label for="mce-group[8529]-8529-5">Teen Programs</label></li>
      <li><input type="checkbox" value="128" name="group[8529][128]" id="mce-group[8529]-8529-6">
        <label for="mce-group[8529]-8529-6">Membership</label></li>
      <li><input type="checkbox" value="34359738368" name="group[8529][34359738368]" id="mce-group[8529]-8529-7">
        <label for="mce-group[8529]-8529-7">Museum Shop</label></li>
      <li><input type="checkbox" value="17592186044416" name="group[8529][17592186044416]" id="mce-group[8529]-8529-8">
        <label for="mce-group[8529]-8529-8">Programs in American Sign Language</label></li>
      <li><input type="checkbox" value="35184372088832" name="group[8529][35184372088832]" id="mce-group[8529]-8529-9">
        <label for="mce-group[8529]-8529-9">Programs for Visitors Who Are Blind or Have Low Vision</label></li>
    </ul>
  </div>

  <div id="mce-responses" class="mailchimp__responses clear">
    <div class="response" id="mce-error-response" style="display:none"></div>
    <div class="response" id="mce-success-response" style="display:none"></div>
  </div>

  <div style="position: absolute; left: -5000px;" aria-hidden="true">
    <input type="text" name="b_387f59a72ae7b64ccae37d5c9_2d1244a32b" tabindex="-1">
  </div>
</div>
</form>
</div>
<script type="text/javascript" src="/whitney/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='MMERGE3';ftypes[3]='zip';fnames[4]='REID';ftypes[4]='text';fnames[5]='MMERGE5';ftypes[5]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup -->

    </div>

    <div class="footer-social">
      <a href="https://www.instagram.com/whitneymuseum/" class="footer-social__link">
        Instagram
      </a>
      <a href="https://www.facebook.com/whitneymuseum" class="footer-social__link">
        Facebook
      </a>
      <a href="https://twitter.com/whitneymuseum" class="footer-social__link">
        Twitter
      </a>
      <a href="https://www.youtube.com/user/WhitneyFocus" class="footer-social__link">
        YouTube
      </a>
    </div>
  </div>

    <div class="footer-nav">
      <p class="footer-nav__title">Get involved</p>

      <ul class="footer-nav__list m-0 p-0">
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/support">Support</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/support/membership">Membership</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/about/job-postings">Jobs &amp; internships</a>
            </li>
      </ul>
    </div>
    <div class="footer-nav">
      <p class="footer-nav__title">About</p>

      <ul class="footer-nav__list m-0 p-0">
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/about">About us</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/about/website">About whitney.org</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/contact">Contact us</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/press">Press</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/collection/images-and-permissions">Images &amp; permissions</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/terms-and-conditions">Terms &amp; conditions</a>
            </li>
            <li class="footer-nav__link m-0">
              <a href="https://whitney.org/privacy-policy">Privacy policy</a>
            </li>
      </ul>
    </div>

  <div class="footer-building">
    <address class="footer__address body-medium">
      <strong>Whitney Museum<br>of American Art</strong><br>
      99 Gansevoort Street<br>
      New York, NY 10014<br>
      (212) 570-3600
    </address>
  </div>
</div>

<div class="footer__secondary">
  <p class="footer__copyright gray">© 2022 Whitney Museum of American Art</p>
</div>
</div>
</footer>

<section class="sunrise-sunset">
<div class="sun-legend">
<div class="sun-legend__header">
  <h2 class="sunrise-sunset__title sunrise-sunset__sunrise">Sunrise</h2>
  <h2 class="sunrise-sunset__title sunrise-sunset__sunset">Sunset</h2>

  <button class="sun-legend__x" type="button"></button>
</div>

<p class="sun-legend__description">
  A 30-second online art project:
  <br>Sara Ludy, <em>Tumbleweeds</em>
</p>

<a class="sun-legend__link body-medium" href="https://whitney.org/artport/sunrise-sunset">Learn more</a>
<p class="sun-legend__static body-small">Learn more at whitney.org/artport</p>
</div></div>`

export default html
