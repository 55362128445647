import React, { useContext, useRef } from "react"
import { useEffectOnce } from "react-use"

/* Import Global Context(s) */
import AudioContext from "~context/audioContext"
import LoadingContext from "~context/loading"
import AudioSamplesContext from "~context/audioSamples"

/* Import Audio Asset URLs */
import cursorClick01Url from "~assets/audio/one-shots/cursor_click_01.wav"
import cursorClick06Url from "~assets/audio/one-shots/cursor_click_06.wav"
import keyboard1Seconds from "~assets/audio/one-shots/keyboard-1-seconds.mp3"
import keyboard3Seconds from "~assets/audio/one-shots/keyboard-3-seconds.mp3"
import keyboard5Seconds from "~assets/audio/one-shots/keyboard-5-seconds.mp3"

const AudioSamplesProvider = ({ children }) => {
  /* Hooks */
  const { setAudioSamplesLoading } = useContext(LoadingContext)
  const { audioContext } = useContext(AudioContext)

  const samplesReference = useRef({
    cursorClick01: {
      url: cursorClick01Url,
      isLoaded: false,
    },
    cursorClick06: {
      url: cursorClick06Url,
      isLoaded: false,
    },
    keyboard5Seconds: {
      url: keyboard5Seconds,
      isLoaded: false,
    },
    keyboard3Seconds: {
      url: keyboard3Seconds,
      isLoaded: false,
    },
    keyboard1Seconds: {
      url: keyboard1Seconds,
      isLoaded: false,
    },
  })

  const playSample = sample => {
    // Return Early if Samples Not Found or Not Loaded
    // console.log("samples", samplesReference.current)
    // console.log("samples[sample]", samplesReference.current[sample])
    // console.log(
    //   "samples[sample].isLoaded",
    //   samplesReference.current[sample].isLoaded
    // )
    if (
      !samplesReference.current[sample] ||
      !samplesReference.current[sample].isLoaded
    ) {
      // console.log("returning early")
      return
    }
    if (audioContext) {
      // Create an AudioBufferSourceNode to play the AudioBuffer
      const source = audioContext.createBufferSource()
      // Set the AudioBuffer of the AudioBufferSourceNode to our Decoded Audio Data
      source.buffer = samplesReference.current[sample]["decodedAudioData"]
      // Connect the AudioBufferSourceNode to the AudioContext
      source.connect(audioContext.destination)
      // Start Playing the New AudioBufferSourceNode
      source.start(0)
    } else {
      // Use Audio Element Created to Playback
      samplesReference.current[sample].audio.play()
    }
    // console.log()
  }

  useEffectOnce(() => {
    const loadSamples = async () => {
      if (audioContext) {
        Object.entries(samplesReference.current).forEach(
          async ([key, { url }]) => {
            // Fetch Audio File from URL
            const response = await fetch(url)
            // Get Response as ArrayBuffer
            const arrayBuffer = await response.arrayBuffer()
            // Decode ArrayBuffer into an AudioBuffer
            const decodedAudioData = await audioContext.decodeAudioData(
              arrayBuffer
            )
            samplesReference.current[key]["decodedAudioData"] = decodedAudioData
            samplesReference.current[key]["isLoaded"] = true
            // console.log(`${key} loaded from ${url}`)
          }
        )
        // console.log("Loaded all audio samples...")
        setAudioSamplesLoading(false)
      } else {
        Object.entries(samplesReference.current).forEach(([key, { url }]) => {
          // Create an Audio Element
          samplesReference.current[key]["audio"] = new Audio(url)
          // Callback when the Audio ELement is fully loaded
          samplesReference.current[key]["audio"].addEventListener(
            "canplaythrough",
            () => {
              samplesReference.current[key]["isLoaded"] = true
              // console.log(`${key} loaded from ${url}`)
            }
          )
        })
        // console.log("Loaded all audio samples...")
        setAudioSamplesLoading(false)
      }
    }

    loadSamples()
  })

  return (
    <AudioSamplesContext.Provider value={{ samplesReference, playSample }}>
      {children}
    </AudioSamplesContext.Provider>
  )
}

export default AudioSamplesProvider
