import React, { useState, useCallback, useEffect } from "react"
import handleWhitneyLoaded from "~components/whitney/logo-script"

/* Import Global Context(s) */
import WhitneyHtmlContext from "~context/whitneyHtml"

const WhitneyHtmlProvider = ({ children }) => {
  const [whitneyHtmlRef, setWhitneyHtmlRef] = useState(null)

  const whitneyHtmlCallbackReference = useCallback(node => {
    if (node !== null) {
      setWhitneyHtmlRef(node)
    }
  }, [])

  useEffect(() => {
    if (whitneyHtmlRef) {
      handleWhitneyLoaded()
    }
  }, [whitneyHtmlRef])

  return (
    <WhitneyHtmlContext.Provider
      value={{ whitneyHtmlRef, whitneyHtmlCallbackReference }}
    >
      {children}
    </WhitneyHtmlContext.Provider>
  )
}

export default WhitneyHtmlProvider
