import { useStaticQuery, graphql } from "gatsby"

const performanceConfigurationQuery = graphql`
  query PerformanceConfigurationQuery {
    allPerformanceJson {
      nodes {
        length
        tickSpeed
      }
    }
  }
`

export const usePerformanceConfiguration = () => {
  const {
    allPerformanceJson: { nodes: performances },
  } = useStaticQuery(performanceConfigurationQuery)

  return performances[0]
}

export default usePerformanceConfiguration
