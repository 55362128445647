import React, { useEffect, useState } from "react"

import LoadingContext from "~context/loading"

const ThreeProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [harpyCompositionLoading, setHarpyCompositionLoading] = useState(true)
  const [harpyBlueCompositionLoading, setHarpyBlueCompositionLoading] =
    useState(true)
  const [nervousSystemCompositionLoading, setNervousSystemCompositionLoading] =
    useState(true)
  const [audioSamplesLoading, setAudioSamplesLoading] = useState(true)
  const [audioLoopLoading, setAudioLoopLoading] = useState(true)

  useEffect(() => {
    if ((!audioSamplesLoading, !audioLoopLoading)) {
      setIsLoading(false)
    }
  }, [audioSamplesLoading, audioLoopLoading])

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        harpyCompositionLoading,
        setHarpyCompositionLoading,
        harpyBlueCompositionLoading,
        setHarpyBlueCompositionLoading,
        nervousSystemCompositionLoading,
        setNervousSystemCompositionLoading,
        audioSamplesLoading,
        setAudioSamplesLoading,
        audioLoopLoading,
        setAudioLoopLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}

export default ThreeProvider
