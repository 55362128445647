import React, { useEffect } from "react"
import * as THREE from "three"
import { useWindowSize } from "react-use"

/* Import Global Hooks */
import useIsBrowser from "~hooks/useIsBrowser"

/* Import Global Context(s) */
import ThreeContext from "~context/three"

const ThreeProvider = ({ children }) => {
  const [renderer, setRenderer] = React.useState(null)
  // Hooks
  // const isBrowser = useIsBrowser()
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  useEffect(() => {
    // Configure Renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setClearColor(0x000000, 0)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(windowWidth, windowHeight)
    setRenderer(renderer)
  }, [])

  useEffect(() => {
    if (renderer) {
      renderer.setSize(windowWidth, windowHeight)
    }
  }, [windowWidth, windowHeight, renderer])

  return (
    <ThreeContext.Provider value={{ renderer }}>
      {renderer ? children : null}
    </ThreeContext.Provider>
  )
}

export default ThreeProvider
