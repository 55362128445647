import { useContext } from "react"

import PlayingContext from "~context/playing"

const useIsPlaying = () => {
  const { isPlaying } = useContext(PlayingContext)

  return !!isPlaying
}

export default useIsPlaying
