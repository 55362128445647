import React, { useState, useEffect } from "react"
import { useKey, useHarmonicIntervalFn } from "react-use"

/* Use Global Queries*/
import usePerformanceConfiguration from "~queries/usePerformanceConfiguration"

/* Import Global Hooks */
import useIsPlaying from "~hooks/useIsPlaying"

/* Import Global Context(s) */
import TimeContext from "~context/time"

const TimeProvider = ({ children }) => {
  const isPlaying = useIsPlaying()
  const { length: performanceLength, tickSpeed } = usePerformanceConfiguration()
  const [playheadPosition, setPlayheadPosition] = useState()

  useEffect(() => {
    const currentUnixTime = Math.floor(Date.now() / 1000)
    setPlayheadPosition(currentUnixTime % performanceLength)
  }, [])

  useHarmonicIntervalFn(
    () => {
      if (playheadPosition >= performanceLength) {
        setPlayheadPosition(0)
      } else {
        const currentUnixTime = Math.floor(Date.now() / 1000)
        setPlayheadPosition(currentUnixTime % performanceLength)
      }
    },
    isPlaying && (playheadPosition || playheadPosition === 0)
      ? tickSpeed * 1000
      : null
  )

  return (
    <TimeContext.Provider value={{ performanceLength, playheadPosition }}>
      {children}
    </TimeContext.Provider>
  )
}

export default TimeProvider
