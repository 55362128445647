import React, { useState } from "react"
import { useKey } from "react-use"

import PlayingContext from "~context/playing"

const ThreeProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(true)

  // Toggle Animation Playing on Spacebar
  useKey(" ", () => {
    setIsPlaying(isPlaying => !isPlaying)
  })

  return (
    <PlayingContext.Provider value={{ isPlaying }}>
      {children}
    </PlayingContext.Provider>
  )
}

export default ThreeProvider
