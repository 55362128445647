/* eslint-disable no-undef */
const handleWhitneyLoaded = () => {
  const wType = "left"
  const pointsOptions = {
    left: {
      large: "0,0 0,100 x,0 x,y 100,0",
      small: "0,0 0,100 x,0 x,y 100,0",
    },
    right: {
      large: "0,0 x,100 x,0 100,y 100,0",
      small: "0,0 x,100 x,0 100,y 100,0",
    },
    center: {
      large: "0,0 25,100 50,0 75,y 100,0",
      small: "0,0 25,100 50,0 75,y 100,0",
    },
  }
  const ratio = 5.9575 // 4766 : 800 = 5.9575 : 1
  const container = document.querySelector(".whitney-main")
  const navigations = document.querySelector(".navigations")
  const w = navigations.querySelector(".w")
  const text = w.querySelector(".w__text")
  const svg = w.querySelector(".w__svg")
  const polyline = svg.querySelector("polyline")
  const miniHeaderWrapper = navigations.querySelector(".mini-header-wrapper")
  const miniTitle = navigations.querySelector(".mini-header__title")
  const miniHeaderRight = miniHeaderWrapper.querySelector(".mini-header__right")
  // console.log("text", text)
  let points
  let marginWidth
  let maxHeight
  let navWidth
  let maxWidth
  let navHeight
  let newLeft = "auto"
  let newRight = "auto"
  let offsetY
  let ticking = false
  let leftCurrent
  let rightCurrent
  let leftLarge
  let leftSmall
  let rightLarge
  let rightSmall
  let bodyPadding
  let spacingSmall
  let wMarginHeight
  let opacityFadeHeight
  let spacing

  // Determine width and height of the text
  function generateTextDimensions(height) {
    let width = maxWidth * leftCurrent
    const newHeight = (height * width) / (width + ratio * height)
    let newWidth = ratio * newHeight
    // About ~ 3.4% of WHITNEY text width is width of a single letter stem (163 / 1785)
    const spacing = newWidth * 0.034
    // Left 1 spacing from left, center 0 additional spacing, right 1 spacing from right
    if (wType == "left" || wType == "right") {
      newWidth = newWidth - spacing
    }

    return [newWidth, newHeight, spacing]
  }

  function drawText() {
    let height = maxHeight - offsetY
    let newHeight = 0
    let newWidth = 0
    if (height > 0) {
      if (wType == "left") {
        ;[newWidth, newHeight, spacing] = generateTextDimensions(height)
        newLeft = spacing + "px"
      } else if (wType == "right") {
        ;[newWidth, newHeight, spacing] = generateTextDimensions(height)
        newRight = maxWidth * rightCurrent + spacing + "px"
      } else {
        ;[newWidth, newHeight] = generateTextDimensions(height)
        newLeft = (maxWidth * leftCurrent - newWidth) * leftCurrent + "px"
      }
    }

    // Very jittery on safari if height isn't also set
    Object.assign(text.style, {
      left: newLeft,
      right: newRight,
      width: newWidth + "px",
      height: newHeight + "px",
    })
  }

  function update() {
    ticking = false
    let newHeight = Math.max(0, maxHeight - offsetY)
    let shortPointHeight
    let wOpacity = 1
    let titleOpacity = 0

    if (window.isSmall) {
      newHeight = Math.max(0, maxHeight - offsetY)
      shortPointHeight = 100
      titleOpacity = 1
      wOpacity = ((newHeight / opacityFadeHeight) * 100) / 100
    } else {
      // Shorten the point that "sits" on the nav
      shortPointHeight = Math.max(
        0,
        100 * ((newHeight - navHeight) / newHeight)
      )

      // W opacity
      const remainingHeight = newHeight - navHeight
      if (remainingHeight <= opacityFadeHeight) {
        wOpacity = Math.max(
          0,
          ((remainingHeight / opacityFadeHeight) * 100) / 100
        )
      }

      // Title opacity
      if (remainingHeight + opacityFadeHeight <= opacityFadeHeight) {
        titleOpacity =
          1 -
          (((newHeight - opacityFadeHeight) / opacityFadeHeight) * 100) / 100
        if (titleOpacity > 1) titleOpacity = 1
      }
    }

    Object.assign(w.style, {
      opacity: wOpacity,
    })

    Object.assign(miniTitle.style, {
      opacity: titleOpacity,
    })

    const newPoints = points
      .replaceAll("x", leftCurrent * 100)
      .replaceAll("y", shortPointHeight)
    polyline.setAttribute("points", newPoints)

    // Fade in title based on height of the w
    if (shortPointHeight <= 0) {
      w.classList.remove("active")
      miniTitle.classList.add("active")
    } else {
      w.classList.add("active")
      miniTitle.classList.remove("active")
    }

    // Scrolled enough to change nav state and fix the mini-header
    if (offsetY >= maxHeight + wMarginHeight) {
      navigations.classList.add("inactive")
    } else {
      navigations.classList.remove("inactive")
    }

    svg.style.height = newHeight + "px"

    drawText()
  }

  function setup() {
    // Determine margins and sizing, pulling everything out of the css
    const windowStyle = window.getComputedStyle(document.documentElement)
    maxHeight = parseInt(windowStyle.getPropertyValue("--w-height"))
    navHeight = parseInt(windowStyle.getPropertyValue("--nav-height"))
    bodyPadding = parseInt(windowStyle.getPropertyValue("--body-padding"))
    spacingSmall = parseInt(windowStyle.getPropertyValue("--spacing-small"))
    marginWidth = parseFloat(window.getComputedStyle(container).paddingLeft)

    opacityFadeHeight = bodyPadding
    navWidth = container.offsetWidth - marginWidth * 2
    maxWidth = navWidth
    offsetY = window.scrollY
    // Spacing above and below the w
    wMarginHeight = bodyPadding + spacingSmall

    // Set w half variables
    if (wType == "left") {
      // Set left section size so that the w "sits" at the edge of the nav buttons
      const wLeftEdge = w.getBoundingClientRect().x
      // Note: this can be off by ~10px and also affected by the mini-header__menu presence
      const buttonsLeftEdge = miniHeaderRight.getBoundingClientRect().x
      const strokeWidth = parseFloat(
        window.getComputedStyle(polyline).strokeWidth
      )
      const usableWidth = buttonsLeftEdge - wLeftEdge + strokeWidth / 2
      leftLarge = usableWidth / maxWidth
    } else if (wType == "right") {
      leftLarge = 0.4
    } else {
      leftLarge = 0.5
    }
    rightLarge = 1 - leftLarge
    leftSmall = 0.5
    rightSmall = 0.5

    // May not be set by the time this runs
    window.isSmall = !!(window.innerWidth < 800)
    // Set size specific values, and classes
    if (window.isSmall) {
      points = pointsOptions[wType].small
      leftCurrent = leftSmall
      rightCurrent = rightSmall
    } else {
      points = pointsOptions[wType].large
      leftCurrent = leftLarge
      rightCurrent = rightLarge
    }

    update()
    drawText()

    Object.assign(text.style, { right: newRight, display: "block" })
  }

  function requestTick() {
    // Skip rendering using RAF until update has run
    if (!ticking) requestAnimationFrame(update)
    ticking = true
  }

  function onScroll() {
    offsetY = window.scrollY
    requestTick()
  }

  setup()

  window.addEventListener("resize", setup)
  window.addEventListener("scroll", onScroll)
}

export default handleWhitneyLoaded
