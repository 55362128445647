import React, { useContext } from "react"

import WhitneyHtmlContext from "~context/whitneyHtml"

import "./whitney.scss"

import whitneyHtml from "./html.js"

const Whitney = () => {
  const { whitneyHtmlCallbackReference } = useContext(WhitneyHtmlContext)

  return (
    <div
      ref={whitneyHtmlCallbackReference}
      className="whitney"
      dangerouslySetInnerHTML={{ __html: whitneyHtml }}
    />
  )
}

export default Whitney
